<template>
  <div class="checkin">
    <div class="banner">
      <div class="bannerBox">
        <div class="name">自助入住机</div>
      </div>
    </div>
    <!-- 相关参数 -->
    <div class="paramBox">
      <div class="param">
        <div class="title">机器人</div>
        <div class="paramTit">相关参数</div>
        <div class="pas">
          <div class="pasBox">
            <div class="pasOne">
              <div class="li">
                <div class="pasName">机柜</div>
                <div class="pasCont">高：1850</div>
                <div class="pasCont">宽：560</div>
                <div class="pasCont">厚：380</div>
                <div class="pasCont">材质：全钢</div>
              </div>
            </div>
            <div class="pasTwo">
              <div class="li">
                <div class="pasName">屏幕</div>
                <div class="pasCont">屏幕：19 吋电容触摸显示器</div>
                <div class="pasCont">分辨率：1366×768</div>
                <div class="pasCont">广告屏幕：21.5 吋彩色液晶显示器</div>
                <div class="pasCont">分辨率：1920×1080</div>
              </div>
            </div>
            <div class="pasThree">
              <div class="li">
                <div class="pasName">内置主机</div>
                <div class="pasCont">芯片组：H61</div>
                <div class="pasCont">CPU：双核2.7GHz</div>
                <div class="pasCont">内存：DDR3 2GB</div>
                <div class="pasCont">储存：1TB硬盘</div>
                <div class="pasCont">视频输出：HDMI+VGA</div>
              </div>
            </div>
          </div>
          <div class="pasBox">
            <div class="pasOne">
              <div class="li">
                <div class="pasName">发卡器</div>
                <div class="pasCont">房卡类型：RFID</div>
                <div class="pasCont">发卡箱容量：120张</div>
                <div class="pasCont">回收卡箱容量：80张</div>
              </div>
            </div>
            <div class="pasTwo">
              <div class="li">
                <div class="pasName">人脸摄像头</div>
                <div class="pasCont">分辨率：1280×1080</div>
                <div class="pasCont">像素：1500万</div>
              </div>
            </div>
            <div class="pasThree">
              <div class="li">
                <div class="pasName">二维码扫描仪（选配）  </div>
                <div class="pasCont">读取旋转角度：360度</div>
                <div class="pasCont">解码时间：小于2秒</div>
              </div>
            </div>
          </div>
          <div class="pasBox">
            <div class="pasOne">
              <div class="li">
                <div class="pasName">凭条打印机</div>
                <div class="pasCont">打印速度：200mm/秒</div>
                <div class="pasCont">打印纸宽：58mm</div>
                <div class="pasCont" style="margin-bottom:0.24rem">其他功能：缺纸报警、</div>
                <div class="pasCont" style="margin-left:1.2rem;margin-bottom:0.24rem">纸将尽报警、</div>
                <div class="pasCont" style="margin-left:1.2rem">自动切纸</div>
              </div>
            </div>
            <div class="pasTwo">
              <div class="li">
                <div class="pasName">银联卡支付（选配）</div>
                <div class="pasCont">吸入式读卡器+密码键盘</div>
                <div class="pasCont">均通过中国银联认证</div>
              </div>
            </div>
            <div class="pasThree">
              <div class="li">
                <div class="pasName">身份证阅读器</div>
                <div class="pasCont">符合ISO/IEC14443-2规定</div>
                <div class="pasCont">公安部标准通讯接口</div>
              </div>
            </div>
          </div>
        </div>
        <div class="text">
          <div class="tx">自助入住机提高酒店运营效率、降低酒店经营成本，缩短入住时间、提升酒店形象。</div>
        </div>
        <div class="star">* 以上所有数据来自BOT实验室，依据测量方式不同,实际结果可能略有差异。</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
// banner
.banner {
  width: 100%;
  height: 6.85rem;
  background: url("../../../assets/product/checkin.png");
  background-size: 100% 100%;
  .bannerBox {
    width: 12rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .name {
      display: block;
      text-align: left;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #333333;
    }
  }
}
// 相关参数
.paramBox {
  width: 100%;
  background-color: #fff;
  .param {
    width: 12rem;
    margin: 0 auto;
    padding-bottom: 1.2rem;
    text-align: left;
    // margin-top: -0.85rem;
    .title {
      padding: 0.39rem 0;
      padding-top: 0;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      border-bottom: 1px solid #333;
    }
    .paramTit {
      margin-top: 0.38rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333;
    }
    .pas {
      margin-top: 0.79rem;
      display: flex;
      text-align: left;
      flex-direction: column;
      .pasBox {
        display: flex;
        margin-bottom: 0.4rem;
        &:last-of-type{
            margin-bottom: 0;
        }
        .pasOne,
        .pasTwo,
        .pasThree {
          display: flex;
          flex-direction: column;
        }
        .pasOne {
          width: 2.65rem;
          margin-right: 1.25rem;
        }
        .pasTwo {
          width: 3.88rem;
          margin-right: 1.28rem;
        }
        .li {
          display: flex;
          flex-direction: column;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
          &:last-of-type {
            margin-bottom: 0px;
          }
          .pasName {
            font-weight: bold;
            margin-bottom: 0.38rem;
          }
          .pasCont {
            margin-bottom: 0.38rem;
            &:last-of-type {
              margin-bottom: 0rem;
            }
          }
        }
      }
    }
    .text {
      margin-top: 0.63rem;
      border-top: 1px solid #333;
      padding-top: 0.4rem;
      text-align: justify;
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .star {
      margin-top: 0.8rem;
      text-align: left;
      font-size: 0.1rem;
      line-height: 0.1rem;
      font-weight: 300;
      color: #333333;
    }
  }
}
</style>
